import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import { Text, TextButton } from 'wix-ui-tpa/cssVars';
import { Status } from '@wix/ambassador-loyalty-v1-tier/types';

import stylesParams from '../stylesParams';
import { classes, style } from './points-card.st.css';
import { useAccount, useLoyaltyProgram, useNavigationActions, useTiersProgram } from './hooks/context-hooks';
import { DataHook } from '../../../types/data-hook';

export const buildImageSrc = (path: string) => `https://static.wixstatic.com/${path}`;

export const PointsCard: React.FC = () => {
  const { t } = useTranslation();
  const { program } = useLoyaltyProgram();
  const { programSettings } = useTiersProgram();
  const styles = useStyles();
  const { account } = useAccount();
  const { navigateToLoyaltyPage } = useNavigationActions();
  const { isMobile, isRTL } = useEnvironment();
  const totalPointsTotalNumberFont = styles.get(stylesParams.totalPointsTotalNumberFont);
  const iconWidth = Math.max(30, (totalPointsTotalNumberFont.size || 0) * 0.8);
  const iconUrl = program.pointDefinition?.icon?.url;
  const showTiers = programSettings?.status === Status.ACTIVE ?? false;
  const pointsName = program.pointDefinition?.customName;

  return (
    <div data-hook={DataHook.PointsCard} className={style(classes.root, { showTiers, isMobile, isRTL })}>
      <div className={classes.contentContainer}>
        {showTiers ? (
          <Text data-hook={DataHook.PointsCardDescription} className={classes.description}>
            {t(pointsName ? 'app.points-card.available-points-custom' : 'app.points-card.available-points', {
              pointsName,
            })}
          </Text>
        ) : null}
        <div data-hook={DataHook.PointsCardPointsBalance} className={classes.pointsBalance}>
          {iconUrl ? (
            <div className={classes.icon}>
              <img width={iconWidth} src={buildImageSrc(iconUrl)} alt="Icon" />
            </div>
          ) : null}
          <Text className={classes.points}>{account.pointsBalance}</Text>
        </div>
        {showTiers ? null : (
          <Text data-hook={DataHook.PointsCardDescription} className={classes.description}>
            {t(pointsName ? 'app.points-card.total-points-earned-custom' : 'app.points-card.total-points-earned', {
              pointsName,
            })}
          </Text>
        )}
        <div className={classes.cta} data-hook={DataHook.ButtonLearnMoreWrapper}>
          <TextButton
            data-hook={DataHook.ButtonLearnMore}
            className={classes.linkToLoyalty}
            onClick={() => navigateToLoyaltyPage()}
          >
            {pointsName
              ? t('app.points-card.learn-how-to-earn-custom', { pointsName })
              : t('app.points-card.learn-how-to-earn')}
          </TextButton>
        </div>
      </div>
    </div>
  );
};
