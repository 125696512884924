import React from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { ProgressBar, Text } from 'wix-ui-tpa/cssVars';
import { useStyles } from '@wix/tpa-settings/react';

import { SimpleReward } from '../../../types/domain';
import { style, classes } from './reward-progress.st.css';
import stylesParams, { Alignment, RewardsLayout } from '../stylesParams';
import { DataHook } from '../../../types/data-hook';
import { useAccount, useLoyaltyProgram } from './hooks/context-hooks';

const layoutToStateMap = {
  [RewardsLayout.Cards]: 'cards',
  [RewardsLayout.List]: 'list',
};

const alignmentToStateMap = {
  [Alignment.Left]: 'left',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'right',
};

type RewardProgressProps = {
  reward: SimpleReward;
};

export const RewardProgress = ({ reward }: RewardProgressProps) => {
  const { t } = useTranslation();
  const { program } = useLoyaltyProgram();
  const { account } = useAccount();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const rewardsLayout = styles.get(stylesParams.rewardsLayout) as RewardsLayout;
  const cardsAlignment = styles.get(stylesParams.cardsItemsAlignment) as Alignment;

  return (
    <div
      data-hook={DataHook.RewardsListItemProgressBar}
      className={style(classes.root, {
        layout: layoutToStateMap[rewardsLayout],
        alignment: alignmentToStateMap[cardsAlignment],
        isMobile,
      })}
    >
      <div className={classes.progressBarContainer}>
        <ProgressBar
          value={Math.floor(((account.pointsBalance ?? 0) / (reward.requiredPoints ?? 1)) * 100)}
          className={classes.progressBar}
        />
      </div>
      <Text data-hook={DataHook.RewardProgressPointsNeeded} className={classes.pointsNeeded}>
        {t(
          program.pointDefinition?.customName
            ? 'app.reward-progress.points-needed-custom'
            : 'app.reward-progress.points-needed',
          {
            pointsName: program.pointDefinition?.customName,
            pointsNeeded: (reward.requiredPoints ?? 0) - (account.pointsBalance ?? 0),
          },
        )}
      </Text>
    </div>
  );
};
